import { defineStore } from 'pinia'

export const useCountStore = defineStore('count', {
  state: () => {
    return {
      counter: 1
    }
  },

  actions: {
    increment() {
      this.counter++;
    }
  }
});
