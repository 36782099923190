<script setup lang="ts">
  import { useCountStore } from './stores/testi'
  const count = useCountStore();
</script>

<template>
  <p>Eka appi</p>
  <button @click="count.increment()">Paina</button>
  <p>{{ count.counter }}</p>
</template>

<style scoped lang="scss">
@import '/src/assets/css/variables.scss';

</style>
