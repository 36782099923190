import { createApp } from 'vue'

// styles
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/main.scss'

import { createPinia } from 'pinia'
import App from './App.vue'
import App2 from './App2.vue'

const joo = createPinia();

const app = createApp(App)
app.use(joo)
app.mount('#app')

const app2 = createApp(App2)
app2.use(joo)
app2.mount('#app2')
