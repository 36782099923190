<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { useCountStore } from './stores/testi'

  const count = useCountStore();
  const { counter } = storeToRefs(count);

  count.$subscribe((_mutation, _state) => {
    console.log("MOI");
  });
</script>

<template>
  <p>Toka appi</p>
  <p>Counter: {{ counter }}</p>
  <button @click="count.increment()">Paina</button>
</template>

<style scoped lang="scss">
@import '/src/assets/css/variables.scss';

</style>
